import React from 'react';
import styles from './Header.module.css';
import programaMasImg from '../../../../assets/media/logos/LOGO_MAS.png';
import gskLRGB from '../../../../assets/media/logos/GSK_logo.png';
import { Link } from 'react-router-dom';
import { PatologiasListLink } from './PatologiasListLink'
import { InicioListLink } from './InicioListLink';
import { HeaderMobile } from './HeaderMobile/HeaderMobile';


const Header = () => {

	const currentPath = window.location.pathname;

	return  <div className={styles.Header}>
    {/*begin::Header*/}<HeaderMobile />
					<div id="kt_header" className={`header header-fixed ${styles.nav}`}>

					
						{/*begin::Container*/}
						<div style={{backgroundColor:'white'}} className="container-fluid d-flex align-items-stretch justify-content-between">
					
							{/*begin::Header Menu Wrapper*/}
							<div className="header-menu-wrapper header-menu-wrapper-left flex-grow-1 justify-content-between" id="kt_header_menu_wrapper">
							
								{/*begin::Header Logo*/}
								<div className="header-logo">
									<Link to="/" className="d-flex align-items-center">
										<img alt="Logo" src={gskLRGB} className="gsk mr-5"></img>
										<img alt="Programa Mas" src={programaMasImg} className="programa-mas mr-5"/>
									</Link>
								</div>

								{/*end::Header Logo*/}

								{/*begin::Header Menu*/}
								<div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-tab header-menu-root-arrow mr-4">

									{/*begin::Header Nav*/}
									<ul className="menu-nav">
										<InicioListLink />
										<PatologiasListLink />
										<li className="menu-item menu-item-submenu menu-item-rel">
											<Link to="/registro" className="menu-link">
												<span className={`menu-text ${ currentPath === '/enrolamiento' ? styles.menuRed: null } ${styles.menuHover}`}>Registro</span>
											</Link>
										</li>
										<li className="menu-item menu-item-submenu menu-item-rel">
											<Link to="/ingresar" className="menu-link">
												<span className={`menu-text ${styles.menu}`}>Ingresar</span>
											</Link>
										</li>
									</ul>

								{/*end::Header Nav*/}
								</div>

							{/*end::Header Menu*/}
								
							</div>

							{/*end::Header Menu Wrapper*/}

						</div>

						{/*end::Container*/}
					</div>

					{/*end::Header*/}
  </div>
}
 


Header.propTypes = {};

Header.defaultProps = {};

export default Header;
