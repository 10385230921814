import React from 'react';
import styles from './Footer.module.css';
import gskLRGB from '../../../../assets/media/logos/GSK_logo.png';
import gskPieLegal from '../../../../assets/media/logos/pie-legal.png';
import { Link } from 'react-router-dom';


const Footer = () => (
	<footer className="site-footer">
            <div className="footer-desktop">
                <nav className="navbar navbar-expand-lg navbar-light nav-footer">
                    <div className="container-fluid">
                        <div className="justify-content-start">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link text-upper" href="/condiciones-de-uso">Términos y Condiciones</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-upper" href="/condiciones-de-uso">Aviso de Privacidad</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-upper" href="/politicas-de-cookies">Política de Cookies</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-upper" href="/preguntas-frecuentes">PREGUNTAS FRECUENTES</a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-right d-flex align-items-center">
                            <a className="nav-link px-0" href="https://www.gsk.com/en-gb/contact-us/worldwide/argentina/" target="_blank" rel="noopener noreferrer">
                                &#169; GSK Biopharma Argentina S.A. 
                                <img alt="Logo" src={ gskLRGB } width="50" className="gsk me-xl-3" />
                            </a>
                            <a className="nav-link pie-legal" href="https://www.argentina.gob.ar/aaip/datospersonales/reclama/20175000640--RL-2019-72708075-APN-DNPDP#AAIP" target="_blank" rel="noopener noreferrer">
                                <img alt="Logo" src={ gskPieLegal } className="me-3" />
                            </a>
                        </div>
                    </div>
                </nav>
                <div className="container-fluid footer-desktop-text">
                    <div className="row">
                        <div className="col-12">
                            <p className="footer-text">
                                GSK Biopharma Argentina S.A.<br />
                                Av. Del libertador 7202<br />
                                Piso 4, CABA, Argentina<br />
                                Tel: <a className="footer-tel" href="tel:08009999327">0800-9999-327 (DAR)</a>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <p className="footer-text mx-auto" style={{ width: 'fit-content', marginBottom: '0px' }}>Solo para público general de Argentina</p>
                            <p className="footer-text mx-auto" style={{ width: 'fit-content' }}>&#169; GSK Biopharma Argentina S.A. Todos los derechos reservados.</p>
                            <p className="footer-text mx-auto" style={{ width: 'fit-content' }}>NP-AR-MLV-WCNT-230001</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-mobile nav-footer">
                <div className="container-fluid pt-4 pb-3">
                    <div className="row">
                        <div className="nav-item col-6 align-self-center">
                            <a className="nav-link text-cap" href="/terminos">Términos y Condiciones</a>
                        </div>
                        <div className="nav-item col-6 align-self-center">
                            <a className="nav-link text-cap" href="/politica-privacidad">Aviso de Privacidad</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="nav-item col-6 align-self-center">
                            <a className="nav-link text-cap" href="/politica-cookies">Política de Cookies</a>
                        </div>
                        <div className="nav-item col-6 align-self-center">
                            <a className="nav-link text-cap" href="/preguntas-frecuentes">Preguntas Frecuente</a>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <p className="footer-text">
                                GSK Biopharma Argentina S.A.<br />
                                Av. Del libertador 7202<br />
                                Piso 4, CABA, Argentina<br />
                                Tel: <a className="footer-tel" href="tel:08009999327">0800-9999-327 (DAR)</a>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <p className="footer-text mx-auto" style={{ width: 'fit-content', marginBottom: '0px' }}>Solo para público general de Argentina</p>
                            <p className="footer-text mx-auto" style={{ width: 'fit-content' }}>&#169; GSK Biopharma Argentina S.A. Todos los derechos reservados.</p>
                            <p className="footer-text mx-auto" style={{ width: 'fit-content' }}>NP-AR-UCV-WCNT-210002</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="footer-right d-flex align-items-center">
                                <a className="nav-link pie-legal" href="https://www.argentina.gob.ar/aaip/datospersonales/reclama/20175000640--RL-2019-72708075-APN-DNPDP#AAIP" target="_blank" rel="noopener noreferrer">
                                    <img alt="Logo" src="/img/pie-legal.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="footer-right d-flex align-items-center">
                                <a className="nav-link px-0" href="https://www.gsk.com/en-gb/contact-us/worldwide/argentina/" target="_blank" rel="noopener noreferrer">
                                    &#169; GSK Biopharma Argentina S.A. 
                                    <img alt="Logo" src="/img/GSK_logo.png" className="gsk me-xl-3" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
				<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4" crossorigin="anonymous"></script>
            </div>
        </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
