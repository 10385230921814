import React from 'react';
import PropTypes from 'prop-types';
import styles from './Hero.module.css';
import gskBoston from '../../../../assets/media/images/WL_GSK_Boston_D5_S1_Canon_356-min.jpg';
import { Link } from 'react-router-dom';
const Hero = () => (
  <section id="hero">
  <div style={{backgroundImage: "url("+gskBoston+")"}} className="fullscreen-slider parallax d-flex align-items-end align-items-lg-center justify-content-center">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-6">
          <h1 className="text-uppercase font-weight-boldest text-left">Programa mas</h1>
          <h2 className="text-uppercase font-weight-boldest text-left">Mejorando el <span>acceso</span> y la <span>adherencia</span> al tratamiento.</h2>
          <p className="my-5 my-lg-7 text-left">Completá el formulario de registro y nosotros nos ocupamos de tu cuenta.</p>
          <Link to="/registro" className="btn btn-primary mb-3 mr-3 allign-left" style={{fontWeight:'bold', paddingLeft: 9, fontSize:'1.231rem'}}>Registrarme</Link>
          <Link to="/ingresar" className="btn btn-primary mb-3 allign-left" style={{fontWeight:'bold', fontSize:'1.231rem'}}>Ingresar</Link>
        </div>
      </div>
    </div>
  </div>
</section>
);

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
