import { Helmet } from "react-helmet";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from "./pages/public/Home/Home";
import Enrolamiento from "./pages/public/Enrolamiento/Enrolamiento";
import CondicionesDeUso from "./pages/public/CondicionesDeUso/CondicionesDeUso";
import ProteccionDeDatos from "./pages/public/ProteccionDeDatos/ProteccionDeDatos";
import Patologias from "./pages/components/Home/Patologias/Patologias";
import ForgotPassword from "./pages/public/ForgotPassword/ForgotPassword";
import ChangePassword from "./pages/public/ChangePassword/ChangePassword";
import Ingresar from "./pages/public/Ingresar/Ingresar";
import { ToastContainer } from "react-toastify";
import PoliticasDeCookies from "./pages/public/PoliticaDeCookies/PoliticasDeCookies";


function App() {
  return (
    <div className="App">
      <div className="application">
        <Helmet>
          <base href="" />
          <meta charset="utf-8" />
          <title>GSK Portal PSP Web</title>
          <meta name="description" content="Portal web de GSK." />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <script src="https://unpkg.com/babel-standalone@6/babel.min.js"></script>
          <link href="assets/plugins/custom/fullcalendar/fullcalendar.bundle.css" rel="stylesheet" type="text/css" />

          <link href="assets/plugins/global/plugins.bundle.css" rel="stylesheet" type="text/css" />
          <link href="assets/plugins/custom/prismjs/prismjs.bundle.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/style.bundle.css" rel="stylesheet" type="text/css" />

          <link href="assets/css/themes/layout/header/base/light.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/themes/layout/header/menu/light.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/themes/layout/brand/light.css" rel="stylesheet" type="text/css" />
          <link href="assets/css/themes/layout/aside/light.css" rel="stylesheet" type="text/css" />

          <link href="assets/css/style-gsk.css" rel="stylesheet" type="text/css" />
          <link rel="shortcut icon" href="../src/assets/media/logos/GSK_logo.png" />

          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/5.3.0/css/bootstrap.min.css" integrity="sha384-oP9xBQrjUbL2NZH2PvH6DTr+zmDb6dbO0S0LtJTAyW57E6ZfD4hX3J0zGZT7xg1f" crossorigin="anonymous"/>
    <script src="https://code.jquery.com/jquery-3.6.0.slim.min.js" integrity="sha384-KyZXEAg3QhqLMpG8r+Knujsl5/6JwFqI01W5f1cG18z45sc6S0N5k7D0zIkfLzFQ" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js" integrity="sha384-eMNldCayENrhlGgWnC91Z7GpucQlL6ccQKcIm7Pc1ldZm2ExhW8STLgSQbr3fr9c" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/5.3.0/js/bootstrap.min.js" integrity="sha384-RXw3M4Q4yqzvJi4v1U+1j5FLV3nAvV8s5d8H3kB7F4kb5Ih/5B3+9nl2NzGrK9fF" crossorigin="anonymous"></script>


		{/* <script src="assets/plugins/global/plugins.bundle.js"></script>
		<script src="assets/plugins/custom/prismjs/prismjs.bundle.js"></script>
		<script type="text/babel" src="assets/js/scripts.bundle.js"></script>
		<script src="assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
		<script src="assets/js/pages/features/calendar/google.js"></script>
		<script src="assets/js/pages/widgets.js"></script> */}

        </Helmet>
      </div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={ <Home />} />
          <Route exact path="/patologias" element={ <Patologias />} />
          <Route exact path="/proteccion-de-datos" element={ <ProteccionDeDatos />} />
          <Route exact path="/registro" element={ <Enrolamiento />} />
          <Route exact path="/condiciones-de-uso" element={ <CondicionesDeUso />} />
          <Route exact path="/politicas-de-cookies" element={ <PoliticasDeCookies />} />
          <Route exact path="/forgot-password" element={ <ForgotPassword />} />
          <Route exact path="/change-password/:passwordToken" element={ <ChangePassword />} />
          <Route exact path="/ingresar" element={ <Ingresar />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
